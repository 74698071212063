<template>
  <div class="container" id="label_orders_edit">
    <h1 v-html="$t('label_order_edit_label_belonging')"></h1>
    <!-- <h1 v-if="labelOrder.dairy">{{ labelOrder.dairy.id }}: {{ labelOrder.dairy.name }}</h1> -->
    <h1>{{ dairyIdent }}: {{ dairy.legalEntityName1 }} {{ dairy.legalEntityName2 }} {{ errorMessage }}</h1>
    <v-form ref="form" @submit.prevent="addLabelBundle()">
      <!-- Zugehörige Label -->
      <v-row v-if="dairyExists">
        <v-col cols="6" sm="12" md="6">
          <VDataTableVirtual
            density="compact"
            :loading="loading"
            :headers="fields"
            :items="labelOrders"
            :hide-default-footer="true"
            disable-pagination
            hover
            class="no-margin-top"
          >
            <template v-slot:[`item.validFrom`]="{ item, index }">
              <dbmDatePicker
                class="nomessage"
                v-model="item.validFrom"
                :required="true"
                :loading="item.updateLoading"
                @change="updateLabelBundle(item, index)"
              ></dbmDatePicker>
            </template>
            <template v-slot:[`item.validUntil`]="{ item, index }">
              <dbmDatePicker
                :loading="item.updateLoading"
                class="nomessage"
                v-model="item.validUntil"
                :clearable="true"
                @change="updateLabelBundle(item, index)"
              ></dbmDatePicker>
            </template>
          </VDataTableVirtual>
        </v-col>
      </v-row>
      <!-- Hinzufügen -->
      <v-row v-if="dairyExists">
        <v-col cols="6" sm="12" md="6">
          <v-btn color="primary" class="shadow-none" @click="openLabelBundleDialogue()"><span v-html="$t('label_order_edit_add_label_bundle')" /></v-btn>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <v-btn color="primary" class="shadow-none top-border" @click="close()"><span v-html="$t('close')" /></v-btn>
        </div>
      </v-row>
      <!-- Search person dialogue -->
      <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="add_label_bundle_dialog">
        <v-card>
          <v-card-title><span v-html="$t('add_label_bundle_title')" /></v-card-title>
          <v-card-text>
            <v-row class="row search spacer-md" v-if="labelBundle">
              <v-select
                id="add_label_bundle_label_bundles"
                v-model="labelBundle.id"
                :label="$t('add_label_bundle_label_bundles')"
                :items="labelBundles"
                item-title="name"
                item-value="id"
                clearable
              />
            </v-row>
            <v-row>
              <DbmDatePicker v-model="labelBundle.validFrom" :dateLabel="$t('add_label_bundle_valid_from')" :required="true" />
            </v-row>
            <v-row>
              <DbmDatePicker v-model="labelBundle.validUntil" :dateLabel="$t('add_label_bundle_valid_until')" :clearable="true" />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn id="submit-btn" class="m-2" color="primary" :loading="labelBundleLoading" type="submit"
              ><span v-html="$t('label_order_edit_add_label_bundle')"
            /></v-btn>
            <v-btn id="close-btn" class="m-2" color="secondary" @click="closeDialog()"><span v-html="$t('cancel')" /></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import _ from 'lodash'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { Term } from '@/services/term'
import { DTSHeader } from '@/services/BackendService'
import { useLabelBundleStore } from '@/store/enums/LabelBundleStore'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'labelOrders',
  props: { dairyIdent: { Number } },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  data() {
    return {
      labelOrders: [],
      labelBundle: {},
      fields: <DTSHeader[]>[
        // Label Bundle
        {
          title: this.$t('add_label_bundle_label_bundles'),
          key: 'labelBundle.name',
          sortable: false
        },

        // Von
        {
          title: this.$t('add_label_bundle_valid_from'),
          key: 'validFrom',
          sortable: false
        },
        // Bis
        {
          title: this.$t('add_label_bundle_valid_until'),
          key: 'validUntil',
          sortable: false
        }
      ],
      loading: false,
      dialog: false,
      labelBundleLoading: false,
      dairies: [],
      dairy: {},
      dairyExists: false,
      errorMessage: ''
    }
  },
  computed: {
    computed: {
      labelBundles() {
        useLabelBundleStore().labelBundles
      }
    },
    term(): any {
      return [...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)]
    }
  },
  methods: {
    async loadLabelOrders() {
      this.loading = true
      try {
        const result = await this.axios.get(apiURL + '/labelOrders', {
          params: {
            term: Term.stringify(this.term).split(',')[0]
          }
        })
        this.labelOrders = result.data
        this.labelOrders.forEach((item) => (item.updateLoading = false))
        return result.data
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async updateLabelBundle(item, index) {
      try {
        item.updateLoading = true
        let sendItem = _.omit(item, ['updateLoading'])
        const result = await this.axios.patch(apiURL + '/labelOrders/' + item.id, {
          ...sendItem
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        item.updateLoading = false
        //we need to re render the table for the loader
        this.labelOrders.splice(index, 1, item)
      }
    },
    close() {
      this.$router.push({ name: 'label_orders_read' }).catch(() => {
        /* duplicated route */
      })
    },
    openLabelBundleDialogue() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    async addLabelBundle() {
      this.labelBundleLoading = true
      try {
        const result = await this.axios.post(
          apiURL + '/labelOrders/',
          {
            labelBundle: { id: this.labelBundle.id },
            dairy: { id: this.dairy.localUnitId },
            validFrom: this.labelBundle.validFrom,
            validUntil: this.labelBundle.validUntil
          },
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.loadLabelOrders()
          this.dialog = false
        }
      } catch (e) {
        showError(e)
      } finally {
        this.labelBundleLoading = false
      }
    },
    async checkIfDairyExists() {
      this.dairyLoading = true
      try {
        this.dairies = []
        const response = await this.axios.get(apiURL + '/masterdata/searchDairy', {
          params: {
            term: Term.stringify(this.term).split(',')[0]
          }
        })
        const items = await response.data.content
        this.dairies = Array.isArray(items) ? items : []
        if (this.dairies.length > 0) {
          this.dairy = this.dairies[0]
          this.dairyExists = true
          return true
        } else {
          this.errorMessage = this.$t('label_orders_edit_no_dairy_error')
          return false
        }
      } catch (e) {
        showError(e)
      } finally {
        this.dairyLoading = false
      }
    }
  },
  beforeMount() {
    useLabelBundleStore().fetchLabelBundles()
  },
  async mounted() {
    if (await this.checkIfDairyExists()) {
      this.loadLabelOrders()
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.top-border {
  margin-top: $spacer-md !important;
}
</style>
